<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        For each of the numbers listed below, determine the number of significant figures.
      </p>

      <p class="mb-0">
        <stemble-latex :content="'a) ' + number1" />
      </p>

      <v-radio-group v-model="inputs.input1" row :disabled="!allowEditing" class="mt-1 pl-6">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block pr-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-0">
        <stemble-latex :content="'b) ' + number2" />
      </p>

      <v-radio-group v-model="inputs.input2" row :disabled="!allowEditing" class="mt-1 pl-6">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block pr-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-0">
        <stemble-latex content="c)" />
        <number-value :value="number3" />
      </p>

      <v-radio-group v-model="inputs.input3" row :disabled="!allowEditing" class="mt-1 pl-6">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block pr-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-0">
        <stemble-latex :content="'d) ' + number4" />
      </p>

      <v-radio-group v-model="inputs.input4" row :disabled="!allowEditing" class="mt-1 pl-6">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block pr-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-0">
        <stemble-latex content="e)" />
        <number-value :value="number5" />
      </p>

      <v-radio-group v-model="inputs.input5" row :disabled="!allowEditing" class="mt-1 pl-6">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block pr-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import LatexNumber from '../displayers/LatexNumber';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '@/tasks/components/NumberValue.vue';

export default {
  name: 'SigFigs1b',
  components: {
    NumberValue,
    LatexNumber,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
      options1: [
        {text: '1', value: '1'},
        {text: '2', value: '2'},
        {text: '3', value: '3'},
        {text: '4', value: '4'},
        {text: '5', value: '5'},
        {text: '6', value: '6'},
        {text: '7', value: '7'},
        {text: '8', value: '8'},
      ],
    };
  },
  computed: {
    number1Integer() {
      return this.taskState.getValueBySymbol('number1Integer').content;
    },
    number2Integer() {
      return this.taskState.getValueBySymbol('number2Integer').content;
    },
    number3() {
      return this.taskState.getValueBySymbol('number3').content;
    },
    number4Integer() {
      return this.taskState.getValueBySymbol('number4Integer').content;
    },
    number5() {
      return this.taskState.getValueBySymbol('number5').content;
    },
    number1() {
      if (this.number1Integer.value === 1) {
        return '$368$';
      } else if (this.number1Integer.value === 2) {
        return '$566$';
      } else if (this.number1Integer.value === 3) {
        return '$894$';
      } else if (this.number1Integer.value === 4) {
        return '$97$';
      } else if (this.number1Integer.value === 5) {
        return '$8156$';
      } else {
        return '$241$';
      }
    },
    number2() {
      if (this.number2Integer.value === 1) {
        return '$1943$';
      } else if (this.number2Integer.value === 2) {
        return '$8217$';
      } else if (this.number2Integer.value === 3) {
        return '$124$';
      } else if (this.number2Integer.value === 4) {
        return '$87$';
      } else if (this.number2Integer.value === 5) {
        return '$36$';
      } else {
        return '$729$';
      }
    },
    number4() {
      if (this.number4Integer.value === 1) {
        return '$308$';
      } else if (this.number4Integer.value === 2) {
        return '$7056$';
      } else if (this.number4Integer.value === 3) {
        return '$1204$';
      } else if (this.number4Integer.value === 4) {
        return '$90731$';
      } else if (this.number4Integer.value === 5) {
        return '$20402$';
      } else {
        return '$3408$';
      }
    },
  },
};
</script>
